/***
 *
 * @param {number[][]} coordinates
 */
const getCenterPoint = (coordinates) => {
  let totalLng = 0;
  let totalLat = 0;

  for (const coordinate of coordinates) {
    totalLng += parseFloat(coordinate[0]);
    totalLat += parseFloat(coordinate[1]);
  }

  return [
    totalLng / coordinates.length,
    totalLat / coordinates.length,
  ]

}


export {getCenterPoint}
